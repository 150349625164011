export const role_0 = [
  {
    id: 'index',
    name: '首页',
    icon: 'home',
    path: '/index/home'
  },
  {
    id: 'account',
    name: '账户管理',
    icon: 'profile',
    children: [
      {
        id: 'account.info',
        name: '基础信息',
        path: '/index/accountInfo'
      },
      {
        id: 'account.edit',
        name: '账户信息',
        path: '/index/accountEdit'
      },
    ]
  },
  {
    id: 'task',
    name: '快捷结算',
    icon: 'project',
    children: [
      {
        id: 'task.add',
        name: '外包结算',
        path: '/index/addTask'
      },
      {
        id: 'task.list',
        name: '外包记录',
        path: '/index/taskList'
      }
    ]
  },
  {
    id: 'user',
    name: '供应管理',
    icon: 'team',
    children: [
      {
        id: 'user.jia',
        name: '公司列表',
        path: '/index/jiaList'
      },
      {
        id: 'user.yi',
        name: '服务商列表',
        path: '/index/yiList'
      },
      {
        id: 'user.bing',
        name: '分包商列表',
        path: '/index/bingList'
      },
      {
        id: 'user.operator',
        name: '操作员列表',
        path: '/index/operatorList'
      },
      {
        id: 'user.add',
        name: '添加',
        path: '/index/addUser'
      }
    ]
  },
  {
    id: 'lable',
    name: '标签管理',
    icon: 'team',
    children: [
      {
        id: 'lable.list1',
        name: '项目标签',
        path: '/index/lableList/1'
      },
      {
        id: 'lable.list2',
        name: '服务商标签',
        path: '/index/lableList/2'
      },
    ]
  }
]

export const role_1 = [
  {
    id: 'index',
    name: '首页',
    icon: 'home',
    path: '/index/home'
  },
  {
    id: 'account',
    name: '账户管理',
    icon: 'profile',
    children: [
      {
        id: 'account.info',
        name: '基础信息',
        path: '/index/accountInfo'
      },
      {
        id: 'account.edit',
        name: '账户信息',
        path: '/index/accountEdit'
      },
    ]
  },
  {
    id: 'task',
    name: '快捷结算',
    icon: 'project',
    children: [
      {
        id: 'task.add',
        name: '外包结算',
        path: '/index/addTask'
      },
      {
        id: 'task.list',
        name: '外包记录',
        path: '/index/taskList'
      }
    ]
  },
  {
    id: 'user',
    name: '供应管理',
    icon: 'team',
    children: [
      {
        id: 'user.yi',
        name: '服务商列表',
        path: '/index/yiList'
      },
      {
        id: 'user.bing',
        name: '分包商列表',
        path: '/index/bingList'
      },
      {
        id: 'user.add',
        name: '添加',
        path: '/index/addUser'
      }
    ]
  }
]

export const role_2 = [
  {
    id: 'index',
    name: '首页',
    icon: 'home',
    path: '/index/home'
  },
  {
    id: 'account',
    name: '账户管理',
    icon: 'profile',
    children: [
      {
        id: 'account.info',
        name: '基础信息',
        path: '/index/accountInfo'
      },
      {
        id: 'account.edit',
        name: '账户信息',
        path: '/index/accountEdit'
      },
    ]
  },
  {
    id: 'task',
    name: '快捷结算',
    icon: 'project',
    children: [
      {
        id: 'task.list',
        name: '外包记录',
        path: '/index/taskList'
      }
    ]
  },
  {
    id: 'user',
    name: '供应管理',
    icon: 'team',
    children: [
      {
        id: 'user.jia',
        name: '公司列表',
        path: '/index/jiaList'
      },
      {
        id: 'user.bing',
        name: '分包商列表',
        path: '/index/bingList'
      },
      {
        id: 'user.add',
        name: '添加',
        path: '/index/addUser'
      }
    ]
  }
]

export const role_3 = [
  {
    id: 'index',
    name: '首页',
    icon: 'home',
    path: '/index/home'
  },
  {
    id: 'account',
    name: '账户管理',
    icon: 'profile',
    children: [
      {
        id: 'account.edit',
        name: '账户信息',
        path: '/index/accountEdit'
      },
    ]
  },
  {
    id: 'task',
    name: '快捷结算',
    icon: 'project',
    children: [
      {
        id: 'task.list',
        name: '外包记录',
        path: '/index/taskDetail'
      }
    ]
  },
  {
    id: 'user',
    name: '供应管理',
    icon: 'team',
    children: [
      {
        id: 'user.bing',
        name: '分包商列表',
        path: '/index/bingList'
      },
      {
        id: 'user.add',
        name: '添加',
        path: '/index/addUser'
      }
    ]
  }
]

export const role_4 = []