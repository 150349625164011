<template>
  <div>
    <div class="header-item">
      <div class="header">
        <span>{{timeFix()}} {{userInfo.real_name}}，欢迎回来</span>
        <a-popconfirm title="确定要注销账号吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleLogout">
          <a-button icon="logout" size="small" type="danger" :ghost="true"></a-button>
        </a-popconfirm>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { logout } from '@/utils/api'
export default {
  name: 'H5Layout',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
    document.getElementById("app").style.maxWidth = '750px'
    document.getElementById("app").style.height = '100vh'
    document.getElementById("app").style.overflowY = 'auto'
    document.getElementById("app").style.margin = '0 auto'
    document.getElementById("app").style.boxShadow = '0px 0px 1px 2px rgba(0, 0, 0, .1)'
  },
  methods: {
    ...mapActions(['Logout']),
    timeFix() {
      const time = new Date()
      const hour = time.getHours()
      return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
    },
    handleLogout () {
      logout().then(() => {
        this.Logout()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-item {
  height: 40px;
  .header {
    box-sizing: border-box;
    max-width: 750px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px;
    height: 40px;
    background-color: #001529;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>