import request from '@/utils/request'

export function login (parameter) {
  return request({
    url: '/home/doLogin',
    method: 'post',
    data: parameter
  })
}

export function logout (parameter) {
  return request({
    url: '/home/login_out',
    method: 'post',
    data: parameter
  })
}

export function userList (parameter) {
  return request({
    url: '/home/user_list',
    method: 'post',
    data: parameter
  })
}

export function getUserByRole (parameter) {
  return request({
    url: '/home/user_l',
    method: 'post',
    data: parameter
  })
}

export function userInfo (parameter) {
  return request({
    url: '/home/info',
    method: 'post',
    data: parameter
  })
}

export function createUser (parameter) {
  return request({
    url: '/home/create_user',
    method: 'post',
    data: parameter
  })
}

export function createTask (parameter) {
  return request({
    url: '/home/create_task',
    method: 'post',
    data: parameter
  })
}

export function getGoods (parameter) {
  return request({
    url: '/home/goods',
    method: 'post',
    data: parameter
  })
}

export function taskList (parameter) {
  return request({
    url: '/home/task_list',
    method: 'post',
    data: parameter
  })
}

export function taskDetail (parameter) {
  return request({
    url: '/home/task_detail',
    method: 'post',
    data: parameter
  })
}

export function taskBing (parameter) {
  return request({
    url: '/home/task_bing',
    method: 'post',
    data: parameter
  })
}

export function taskCg (parameter) {
  return request({
    url: '/home/task_cg',
    method: 'post',
    data: parameter
  })
}

export function taskStatus (parameter) {
  return request({
    url: '/home/task_status',
    method: 'post',
    data: parameter
  })
}

export function lableData (parameter) {
  return request({
    url: '/home/lable',
    method: 'post',
    data: parameter
  })
}

export function bingExcelIn (parameter) {
  return request({
    url: '/home/bing_excel_in',
    method: 'post',
    data: parameter
  })
}

export function bingTaskList (parameter) {
  return request({
    url: '/home/bing_task_list',
    method: 'post',
    data: parameter
  })
}

export function bingExcelOut (parameter) {
  return request({
    url: '/home/bing_excel_out',
    method: 'post',
    data: parameter
  })
}

export function excelIn (parameter) {
  return request({
    url: '/home/excel_in',
    method: 'post',
    data: parameter
  })
}

export function upload (parameter) {
  return request({
    url: '/common/upload',
    method: 'post',
    data: parameter
  })
}

export function uploadExcel (parameter) {
  return request({
    url: '/common/upload_excel',
    method: 'post',
    data: parameter
  })
}

