import router from '../router'
import store from '../store'

const allowList = ['login', 'register', '/']

router.beforeEach((to, from, next) => {
  if (store.getters.isLogin) {
    next()
  } else {
    const userInfo = window.sessionStorage.getItem('userInfo')
    const isLogin = window.sessionStorage.getItem('isLogin')
    if (userInfo && isLogin === 'true') {
      store.dispatch('Login', JSON.parse(userInfo))
      next()
    } else {
      if (allowList.includes(to.name)) {
        next()
      } else {
        next({path: '/user/login'})
      }
    }
  }
})