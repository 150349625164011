<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout-header class="header">
      <div class="logo" >灵活用工平台</div>
      <div class="user-item">
        <a style="font-size: 20px; margin-right: 40px;" href="javascript:window.location.reload();"><a-icon type="reload" /></a>
        <a-avatar icon="user" :src="userInfo.header" />
        <span class="username">{{userInfo.username}}</span>
        <a-popconfirm title="确定要注销账号吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleLogout">
          <a-button icon="logout" size="small" :ghost="true"></a-button>
        </a-popconfirm>
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="200" style="background: #fff">
        <a-menu v-if="menuArr.length > 0"
          mode="inline"
          theme="dark"
          :default-selected-keys="defaultSelectedKeys"
          :style="{ height: '100%', borderRight: 0 }"
          :open-keys="openKeys"
          @openChange="onOpenChange"
        >
          <template v-for="item in menuArr">
            <a-sub-menu v-if="item.children" :key="item.id">
              <span slot="title"><a-icon v-if="item.icon" :type="item.icon" />{{item.name}}</span>
              <a-menu-item v-for="subItem in item.children" :key="subItem.id">
                <router-link :to="{ path: subItem.path }">{{subItem.name}}</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item v-else :key="item.id">
              <router-link :to="{ path: item.path }"><a-icon v-if="item.icon" :type="item.icon" />{{item.name}}</router-link>
            </a-menu-item>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 24px">
        <!-- <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item>List</a-breadcrumb-item>
          <a-breadcrumb-item>App</a-breadcrumb-item>
        </a-breadcrumb> -->
        <a-layout-content
          :style="{ background: '#fff', margin: 0, height: 'calc(100vh - 112px)', overflowY: 'auto' }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>

import { role_0, role_1, role_2, role_3, role_4 } from '@/config/menu'
import { mapState, mapActions } from 'vuex'
import { logout } from '@/utils/api'

export default {
  name: 'BasicLayout',
  data () {
    return {
      menuArr: [],
      defaultSelectedKeys: [],
      defaultOpenKeys: [],
      openKeys: [],
      rootSubmenuKeys: []
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  components: {
  },
  methods: {
    ...mapActions(['Logout']),
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    setMenu() {
      const roleId = this.userInfo.role_id
      switch (roleId) {
        case 0:
          this.menuArr = role_0
          break;
        case 1:
          this.menuArr = role_1
          break;
        case 2:
          this.menuArr = role_2
          break;
        case 3:
          this.menuArr = role_3
          break;
        case 4:
          this.menuArr = role_4
          break;
      }
      this.setDefaultKeys()
    },
    setDefaultKeys () {
      let title = this.$route.meta.title
      if (title) {
        if (title == 'lable.list') {
          title = title + (this.$route.params.type || '')
        }
        const openKey = title.split(".")[0]
        this.defaultSelectedKeys = [title]
        this.openKeys = [openKey]
      } else {
        this.defaultSelectedKeys = [this.menuArr[0].id]
        this.openKeys = [this.menuArr[0].id]
      }
      const {menuArr} = this
      for (let index = 0; index < menuArr.length; index++) {
        const element = menuArr[index];
        this.rootSubmenuKeys.push(element.id)
      }
    },
    handleLogout () {
      logout().then(() => {
        this.Logout()
      })
    }
  },
  mounted() {
    this.setMenu()
  }
}
</script>

<style lang="scss" scoped>
#components-layout-demo-top-side-2 {
  .header {
    padding-left: 24px;
  }
  .logo {
    width: 176px;
    color: #fff;
    font-size: 24px;
    float: left;
  }
} 
.user-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .username {
    color: #fff;
    margin: 0 25px 0 5px;
    font-size: 16px;
  }
}
</style>
