import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/public/Home.vue'
import { baseRouterMap, asyncRouterMap } from '@/config/router.config'

Vue.use(VueRouter)

const routes = baseRouterMap.concat(asyncRouterMap)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
