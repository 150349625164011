<template>
  <div>
    <div class="user-layout-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserLayout',
}
</script>

<style lang="scss" scoped>
  .user-layout-wrapper {
    width: 100%;
    min-height: 100vh;
    background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background-size: 100%;
    position: relative;
  }
</style>
