import UserLayout from '@/layouts/UserLayout'
import BasicLayout from '@/layouts/BasicLayout'
import H5Layout from '@/layouts/H5Layout'

export const baseRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/user/Register')
      }
    ]
  }
]

export const asyncRouterMap = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/index/Index')
  },
  {
    path: '/index',
    name: 'index',
    component: BasicLayout,
    redirect: '/index/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/index/Home')
      },
      {
        path: 'jiaList',
        name: 'jiaList',
        meta: {
          title: 'user.jia'
        },
        component: () => import('@/views/index/user/JiaList')
      },
      {
        path: 'yiList',
        name: 'yiList',
        meta: {
          title: 'user.yi'
        },
        component: () => import('@/views/index/user/YiList')
      },
      {
        path: 'bingList',
        name: 'bingList',
        meta: {
          title: 'user.bing'
        },
        component: () => import('@/views/index/user/BingList')
      },
      {
        path: 'operatorList',
        name: 'operatorList',
        meta: {
          title: 'user.operator'
        },
        component: () => import('@/views/index/user/OperatorList')
      },
      
      {
        path: 'userDetail',
        name: 'userDetail',
        meta: {
          title: 'user.detail'
        },
        component: () => import('@/views/index/user/UserDetail')
      },
      {
        path: 'addUser',
        name: 'addUser',
        meta: {
          title: 'user.add'
        },
        component: () => import('@/views/index/user/AddUser')
      },
      {
        path: 'addTask',
        name: 'addTask',
        meta: {
          title: 'task.add'
        },
        component: () => import('@/views/index/task/AddTask')
      },
      {
        path: 'taskList',
        name: 'taskList',
        meta: {
          title: 'task.list'
        },
        component: () => import('@/views/index/task/TaskList')
      },
      {
        path: 'taskDetail',
        name: 'taskDetail',
        meta: {
          title: 'task.detail'
        },
        component: () => import('@/views/index/task/TaskDetail')
      },
      {
        path: 'accountInfo',
        name: 'accountInfo',
        meta: {
          title: 'account.info'
        },
        component: () => import('@/views/index/account/AccountInfo')
      },
      {
        path: 'accountEdit',
        name: 'accountEdit',
        meta: {
          title: 'account.edit'
        },
        component: () => import('@/views/index/account/AccountEdit')
      },
      {
        path: 'lableList/:type',
        name: 'lableList',
        meta: {
          title: 'lable.list'
        },
        component: () => import('@/views/index/lable/LableList')
      }
    ]
  },
  {
    path: '/h5',
    name: 'h5',
    component: H5Layout,
    redirect: '/h5/bingTaskList',
    children: [
      {
        path: 'bingTaskList',
        name: 'bingTaskList',
        meta: {
          title: 'h5.list'
        },
        component: () => import('@/views/index/h5/BingTaskList')
      },
      {
        path: 'bingTaskDetail',
        name: 'bingTaskDetail',
        meta: {
          title: 'h5.detail'
        },
        component: () => import('@/views/index/h5/BingTaskDetail')
      }
    ]
  },
  {
    path: '/index/print/:id',
    name: 'print',
    component: () => import('@/views/index/task/PrintTask')
  }

]