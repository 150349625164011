import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    isLogin: false
  },
  getters: {
    isLogin: state => state.isLogin,
    userInfo: state => state.userInfo,
  },
  mutations: {
    Login(state, params) {
      state.isLogin = true
      state.userInfo = params
      window.sessionStorage.setItem('userInfo', JSON.stringify(params))
      window.sessionStorage.setItem('isLogin', true)
    },
    Logout(state) {
      state.isLogin = false
      state.userInfo = {}
      window.sessionStorage.clear()
      window.location.href = '/#/user/login'
    }
  },
  actions: {
    Login(state, params) {
      state.commit('Login', params)
    },
    Logout(state) {
      state.commit('Logout')
    }
  },
  modules: {
  }
})
